<script lang="ts">
	import { serializeSchema } from './json-ld';
	import type { Schema } from './json-ld';

	export let schema: Schema;
</script>

<!-- 
	This is a temporary solution to add JSON-LD to render in body, adding to svelte:head is duplicating the script tag when using SSR.
 -->
<!-- <svelte:head> -->
{@html serializeSchema(schema)}
<!-- </svelte:head> -->
